<template>
    <div id="carousel">
        <div class="modal-overlay" @click.self="$emit('close')">
            <div class="carousel-wrapper">
                <slot></slot>
                <div v-show="slidesLength > 1" @click="next" class="carousel__next"><img src="/assets/item/other/arrow_next.png" style="width: 30px"></div>
                <div v-show="slidesLength > 1" @click="prev" class="carousel__prev"><img src="/assets/item/other/arrow_before.png" style="width: 30px"></div>
                <div @click="close" class="carousel__close"><img src="/assets/item/other/close_white.png" style="width: 30px"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'visibleSlide',
        'slidesLength'
    ],

    setup(props, context) {
        const next = () => {
        context.emit('next')
        }
        const prev = () => {
        context.emit('prev')
        }
        const close = () => {
        context.emit('close')
        }
        return {
        next,
        prev,
        close
        }
    }
}
</script>

<style lang="sass">
    .modal-overlay 
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        display: flex
        justify-content: center
        align-items: center
        z-index: 2
    #carousel
        position: fixed
        width: 100%
        height: 100%
        top: 0
        left: 0
        background: #000000b8
        z-index: 1
        .carousel-wrapper
            max-width: 50vw
            max-height: 35vw
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            margin: auto
            background-image: url(/assets/bg/clue_two_bg.png)
            background-repeat: no-repeat
            background-position: center
            background-size: contain
        .carousel__next
            position: absolute
            right: 10%
            top: 50%
        .carousel__prev
            position: absolute
            left: 10%
            top: 50%
        .carousel__close
            position: absolute
            right: 0
            bottom: 0
</style>

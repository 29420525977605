<template>
    <div class="modal-overlay" @click.self="$emit('close')">
        <div v-if="!isMobile" class="scroll-paper-wrap">
            <div class="content">
                <img src="/assets/help/sekigahara.jpg">
            </div>
        </div>
        <div v-if="isMobile" class="scroll-paper-wrap-sp">
            <div class="content-sp">
                <img src="/assets/help/sekigahara.jpg">
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
import { inject } from "vue"
export default ({
    name: "DictComponent",
    emits: ["close"],
    setup() {
        const isMobile = inject('isMobile')
        return {
            isMobile
        }
    }
})
</script>
<style lang="scss" scoped>
.modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}
.scroll-paper-wrap {
    background-image: url(/assets/help/scroll_book.png);
    width: 1000px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    overflow-y: scroll;
    height: 500px;
}
.scroll-paper-wrap-sp {
    background-image: url(/assets/help/scroll_book.png);
    width: 150vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    overflow-y: scroll;
    height: 89vh;
}
.content {
    display: grid;
    width: 789px;
    height: 387px;
    overflow-y: scroll;
    position: relative;
    top: 58px;
    left: 107px;
    img {
        max-width: 100%;
        object-fit: contain;
    }
}
.content-sp {
    display: grid;
    width: 140vh;
    height: 67vh;
    overflow-y: scroll;
    position: relative;
    top: 11vh;
    left: 0;
    position: absolute;
    right: 0;
    margin: 0 auto;
    img{
        width: 100%;
    }
}
</style>


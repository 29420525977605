<template>
    <div class="modal-overlay">
        <div class="header">
            <div></div>
            <div class="header-title">
                <img src="/assets/bg/select_card_white.png">
            </div>
            <div class="header-close">
                <img src="/assets/item/other/clue_close.png" @click="$emit('close')">
            </div>
        </div>
        <div class="content">
            <div v-for="[category,cards] in Object.entries(teamItem)" :key="category" class="category-grid">
                <div v-for="card_no in cards" :key="card_no" class="card-case">
                    <img :src="'/assets/item/card/' + category + '/' + card_no + '.png'" @click="$emit('select', category, card_no)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default ({
    name: "ItemShowCaseMobile",
    emits: ["close", "select"],
    setup() {
        const store = useStore()
        const teamItem = computed(() => store.state.teamItem)
        return {
            teamItem,
        }
    }
})
</script>
<style lang="scss" scoped>
.modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-rows: 15% 85%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
}
.header {
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
    align-items: center;
    justify-content: center;
}
.header-title {
    text-align: center;
    img {
        object-fit: contain;
    }
}
.header-close {
    text-align: center;
    img {
        max-width: 20%;
        object-fit: contain;
    }
}
.content {
    width: 100%;
    height: 100%;
    padding: 0% 5%;
    overflow-y: scroll;
}
.category-grid {
    display: flex;
    flex-flow: row wrap;
    border-bottom: solid 2px gray;
}
.card-case {
    width: 25%;
    padding: 1% 1%;
    text-align: center;
    img {
        width: 95%;
        object-fit: contain;
    }
}
</style>

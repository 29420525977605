<template>
    <img v-if="stage == 'lobby'" @click.prevent="logout" src="/assets/bg/lobby_logout.png" class="img-fluid max-z-index">
    <img v-else @click.prevent="logout" src="/assets/item/other/logout.png" class="img-fluid" :class="{'w-15' : isMobile}">
</template>

<script>
import { inject } from 'vue'
import { post } from 'axios'

export default ({
    name: "Logout",
    props: ['stage'],
    setup() {
        const isMobile = inject('isMobile')
        const logout = async () => {
            if (!window.confirm("ログアウトします。よろしいですか？")) return
            const res_st = post("logout").then(
                (res) => res.status
            ).catch(
                err => console.log("logout failed", err)
            )

            if (res_st === 302 || 401) {
                console.log('logout success')
                window.location.href = process.env.MIX_APP_URL + '/login'
            } else {
                console.log("logout error: response status is not correct")
            }
        }

        return {
            isMobile,
            logout,
        }
    }
})
</script>

<style lang="scss" scoped>
    img {
        width: 100%;
        object-fit: scale-down;
    }
    .w-15 {
        width: 15%;
    }
</style>


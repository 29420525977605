<template>
    <img v-if="stage === 'lobby'" src="/assets/bg/lobby_refresh.png"  @click.prevent="refreshData" :class="{'w-50': isMobile}">
    <img v-else-if="isMobile" src="/assets/bg/reload_mobile.png" @click.prevent="refreshData" class="w-80">
    <img v-else src="assets/bg/reload.png" @click.prevent="refreshData" class="img-fluid help reload">
</template>

<script>
import { computed, inject } from "vue"
import { useStore, storeKey } from 'vuex'
import { useRoute } from 'vue-router'

export default ({
    name: "Refresh",
    setup() {
        const route = useRoute()
        const isMobile = inject('isMobile')
        const store = useStore(storeKey)
        const stage = computed(() => store.state.userEvent.event.stage)

        const refreshData = async () => {
            const prevStage = stage.value
            await store.dispatch('fetchUserEvent')
            if (prevStage !== stage.value) {
               window.location.href = process.env.MIX_APP_URL
            }

            if (route.path === '/group') {
                store.dispatch('fetchGroupField')
            } else if (route.path === '/team') {
                store.dispatch('fetchTeamFieldItem')
            }
            if (route.path !== '/lobby') {
                store.dispatch('fetchGroupPoint')
            }
        }

        return {
            isMobile,
            stage,
            refreshData,
        }
    }
})
</script>

<style lang="scss" scoped>
    img {
        object-fit: scale-down;
    }
    .w-80 {
        width: 80%;
    }
    .help {
        width: 25%;
        display: inline-block;
    }
    .reload:active{
        transform: rotate(180deg);
    }
</style>


<template>
    <div id="MapGroup" class="map-group">
        <div class="main-grid">
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/north.png">
            </div>
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/west.png">
            </div>
            <div class="index-grid">
                <div/>
                <div class="index-x">
                    <div class="index-cell" v-for="i in genCell(10)" :key="i">
                        <img :src="'/assets/map/symbol/x/' + i + '.png'" alt="">
                    </div>
                </div>
                <div/>
                <div class="index-y">
                    <div class="index-cell" v-for="i in genCell(10)" :key="i">
                        <img :src="'/assets/map/symbol/y/' + i + '.png'" alt="">
                    </div>
                </div>
                <div class="map">
                    <div class="map-team-grid" v-for="teamField in groupField" :key="teamField">
                        <div
                            class="map-cell"
                            :class="{ 'map-cell-black-out': !f.result && stage === 'result' }"
                            v-for="f in teamField"
                            :key="f"
                            @click.prevent="toggleCardDetail(f.category, f.card_no, true)"
                        >
                            <img v-if="f.category !== ''" :src="'/assets/item/text_card/' + f.category + '/' + f.card_no + '.png'" alt="">
                        </div>
                    </div>
                </div>
                <div class="index-y">
                    <div class="index-cell" v-for="i in genCell(10)" :key="i">
                        <img :src="'/assets/map/symbol/y/' + i + '.png'" alt="">
                    </div>
                </div>
                <div/>
                <div class="index-x">
                    <div class="index-cell" v-for="i in genCell(10)" :key="i">
                        <img :src="'/assets/map/symbol/x/' + i + '.png'" alt="">
                    </div>
                </div>
                <div/>
            </div>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/east.png">
            </div>
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/south.png">
            </div>
            <div/>
        </div>
    </div>
    <ShowCardMobile
        v-show="cardDetail.isShowen"
        @close="toggleCardDetail('', 0, false)"
    />
</template>

<script>
import { useStore, storeKey } from 'vuex'
import { computed, onMounted, reactive, provide} from 'vue'
import ShowCardMobile from '@c/ShowCardMobile'

export default {
    name: "MapGroup",
    components: {
        ShowCardMobile,
    },
    setup() {
        const genCell = (cnt) => {
            return [...Array(cnt).keys()].map(i => ++i)
        };
        const store = useStore(storeKey)
        const stage = computed(() => store.state.userEvent.event.stage)

        const cardDetail = reactive({
            category: "",
            card_no: 0,
            isShowen: false,
        })
        provide('cardDetail', cardDetail)
        const toggleCardDetail = (category, card_no, isShowen) => {
            if (category === "" && card_no == 0 && isShowen === true) return
            cardDetail.category = category
            cardDetail.card_no = card_no
            cardDetail.isShowen = isShowen
        }

        onMounted(async () => {
            // Going to ref store object, so "await" is required.
            await store.dispatch('fetchGroupField')
        })
        const groupField = computed(() => store.state.groupField)

        return {
            genCell,
            stage,
            groupField,
            cardDetail,
            toggleCardDetail,
        }
    }
}
</script>
<style lang="scss" scoped>
.map-group {
    flex-grow: 1;
    padding: 35px;
    background-image: url(/assets/bg/wrap.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    z-index: 2;
    position: relative;
    pointer-events: none;
}
.main-grid {
    width: 100%;
    display: grid;
    grid-template: 5% 90% 5% / 3% 94% 3%;
    pointer-events: auto;
    height: 584px;
}
.direction-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 60%;
        object-fit: contain;
    }
}
.index-grid {
    display: grid;
    grid-template: 5% 90% 5% / 3% 94% 3%;
}
.index-x {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}
.index-y {
    display: grid;
    grid-template-rows: repeat(10, 1fr);
}
:is(.index-x, .index-y) .index-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9e933e;
    border: 1px black solid;
    img {
        max-width: 60%;
        max-height: 60%;
        object-fit: contain;
    }
}
.map {
    background-image: url(/assets/map/map.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    display: grid;
    grid-template: repeat(2,1fr) / repeat(2, 1fr);
}
.map-team-grid {
    display: grid;
    grid-template: repeat(5, 1fr) / repeat(5, 1fr);
    border: 1.2px solid #c1c1c1;
}
.map-cell {
    border: 1px black solid;
    display: grid;
    align-items: center;
    justify-items: center;
    img {
        max-width: 85%;
        object-fit:contain;
    }
}
.map-cell-black-out {
    backdrop-filter: brightness(70%) grayscale(100%);
    img {
        filter: brightness(70%) grayscale(100%);
    }
}
</style>

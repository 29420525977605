<template>
    <div class="main-grid">
        <div class="menu-grid">
            <div class="menu-btn">
                <img :src=" state.clue1Flg? '/assets/bg/clue1_mobile_focus.png' : '/assets/bg/clue1_mobile.png'" @click="toggleModal('clue1', true)">
            </div>
            <div class="menu-btn">
                <img v-if="stage === 'second'" :src=" state.clue2Flg? '/assets/bg/clue2_mobile_focus.png' : '/assets/bg/clue2_mobile.png'" @click="toggleModal('clue2', true)">
            </div>
            <div class="menu-btn">
                <img v-if="route.path === '/group'" src='/assets/bg/map_btn_team_mobile.png' @click="router.push('/team')">
                <img v-if="route.path === '/team'" src="/assets/bg/map_btn_group_mobile.png" @click="toggleItemSelect(false); router.push('/group')">
            </div>
            <div class="menu-btn">
                <div class="user-action">
                    <Logout :stage="stage"/>
                    <Refresh/>
                </div>
            </div>
        </div>
        <Clue1Component
            v-show="state.clue1Flg"
            @close="toggleModal('clue1', false)"
        />
        <Clue2Component
            v-show="state.clue2Flg"
            @close="toggleModal('clue2', false)"
        />
    </div>
</template>
<script>
import { useStore, storeKey } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { reactive, computed } from 'vue'
import Clue1Component from '@c/Clue1Component.vue';
import Clue2Component from '@c/Clue2Component.vue';
import Logout from '@c/Logout';
import Refresh from '@c/Refresh'

export default {
    name: "ItemMobile",
    components: {
        Clue1Component,
        Clue2Component,
        Logout,
        Refresh,
    },
    setup() {
        const store = useStore(storeKey)
        const route = useRoute()
        const router = useRouter()
        const stage = computed(() => store.state.userEvent.event.stage)
        const isItemSelectShowen = computed(() => store.state.isItemSelectShowen)
        const toggleItemSelect = (isShow) => {
            store.dispatch('toggleItemSelect', isShow)
        }
        const state = reactive({
            clue1Flg: false,
            clue2Flg: false
        })
        const toggleModal = (type, flag) => {
            if (type === "clue1") return state.clue1Flg = flag
            if (type === "clue2") return state.clue2Flg = flag
        }
        return {
            toggleItemSelect,
            isItemSelectShowen,
            state,
            toggleModal,
            route,
            router,
            stage,
        }
    }
}
</script>
<style lang="scss" scoped>
.main-grid {
    display: grid;
}
.menu-grid {
    width: 100%;
    margin-top: 20%;
    display: grid;
    grid-template: repeat(4, 1fr) / 1fr;
}
.menu-btn {
    display: grid;
    justify-content: right;
    img {
        object-fit: contain;
    }
}
.user-action {
    display: flex;
    flex-direction: row-reverse;
}
</style>

/**
* Clue1Component
*
*/
<template>
    <div class="modal-overlay" @click.self="$emit('close')">
        <div id="clueOneComponent">
            <section class="container">
                <div class="row">
                    <article class="clue-wrap col-12" @click.right.prevent @copy.prevent @paste.prevent>
                        <div class="">
                            <img src="/assets/item/clue/clue1_title.png" style="width: 30%" class="mb-2">
                        </div>
                        <div v-if="state.team_id === 1" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・本陣【徳川家康】のすぐ南は桃配山（ももくばりやま）がそびえている</p>
                                    <p className="mb-0">・自陣①【黒田長政】のすぐ南東には自陣②【井伊直政】がある</p>
                                    <p className="mb-0">・敵陣②【島津義弘】より南にうっすらと敵陣⑦【平塚為広】がみえる</p>
                                    <p className="mb-0">・自陣②【井伊直政】より西に敵陣②【島津義弘】がある</p>
                                    <p className="mb-0">・自陣①【黒田長政】より南西に敵陣②【島津義弘】が見える</p>
                                    <p className="mb-0">・自陣①【黒田長政】から東にはうっすらと、うつけの焼け跡があるだろう</p>
                                    <p className="mb-0">・ろ-四に自陣①【黒田長政】がある</p>
                                    <p className="mb-0">・たぬき平野の場所は・・・【密書の謎1】</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.team_id === 2" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・敵陣③【小西行長】は敵陣②【島津義弘】の南に布陣している</p>
                                    <p className="mb-0">・本陣【徳川家康】のすぐ北東には自陣⑥【山内一豊】がある</p>
                                    <p className="mb-0">・自陣⑥【山内一豊】よりはるか南の方角に敵陣⑩【毛利秀元】が布陣している</p>
                                    <p className="mb-0">・本陣【徳川家康】の北西には侍修行道場が見える</p>
                                    <p className="mb-0">・自陣⑥【山内一豊】の西には侍修行道場が見える</p>
                                    <p className="mb-0">・本陣【徳川家康】より西には双子茶屋があるだろう</p>
                                    <p className="mb-0">・に-八に本陣【徳川家康】がある</p>
                                    <p className="mb-0">・【密書の謎1-1】 は【密書の謎1-2】の列のどこかにいる</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.team_id === 3" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・あやかし池はと-六にある</p>
                                    <p className="mb-0">・自陣④【藤堂高虎】のすぐ南東には自陣⑤【本多忠勝】がある</p>
                                    <p className="mb-0">・自陣⑤【本多忠勝】より東に敵陣⑧【安国寺恵瓊】の陣がうっすらとみえる</p>
                                    <p className="mb-0">・自陣④【藤堂高虎】より南に進めば首狩り平野が見えてくるだろう</p>
                                    <p className="mb-0">・自陣⑤【本多忠勝】より南西に進めば首狩り平野が見えてくるだろう</p>
                                    <p className="mb-0">・自陣⑤【本多忠勝】より北に行けばそっくりな顔の2人の姉妹が切り盛りする茶屋がある</p>
                                    <p className="mb-0">・へ-四に自陣④【藤堂高虎】がある</p>
                                    <p className="mb-0">・美剣士の沼は【密書の謎1】の列のどこかにある。ハマリすぎ注意</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="state.team_id === 4" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・桃配山のすぐ南に桃配村、ぬ-五には忍びの里がある</p>
                                    <p className="mb-0">・無双の丘のすぐ南西には浪人の酒場がある</p>
                                    <p className="mb-0">・敵陣⑩【毛利秀元】よりはるか北にうつけの焼け跡が見えている</p>
                                    <p className="mb-0">・無双の丘南東にあっぱれ山が見える</p>
                                    <p className="mb-0">・浪人の酒場の東にあっぱれ山が見える</p>
                                    <p className="mb-0">・浪人の酒場より東に敵陣⑩【毛利秀元】あり！！</p>
                                    <p className="mb-0">・ち-七に無双の丘がある</p>
                                    <p className="mb-0">・黄金寺の場所は・・・【密書の謎1】</p>
                                </div>
                            </div>
                        </div>
                        <div className="my-2">
                            <img @click="toggleModal(state.solveFlg)" src="/assets/item/clue/solve_btn.png" style="width:25%">
                        </div>
                        <div class="close">
                            <img @click="$emit('close')" src="/assets/item/other/close_black.png">
                        </div>
                    </article>
                </div>
            </section>
            <Carousel
                v-show="state.solveFlg"
                @close="toggleModal(state.solveFlg)"
                @next="next"
                @prev="prev"
                :visibleSlide="visibleSlide"
                :slidesLength="slides.length"
            >
                <CarouselSlide
                    v-for="(slide, index) in slides"
                    :key="slide"
                    :index="index"
                    :visibleSlide="visibleSlide"
                    >
                    <img :src="slide"/>
                </CarouselSlide>
            </Carousel>
        </div>
    </div>
</template>

<script>
    import { useStore,storeKey } from 'vuex'
    import { ref, reactive, computed } from 'vue'
    import Carousel from '@c/Carousel.vue'
    import CarouselSlide from '@c/CarouselSlide.vue'

    export default ({
        name: "Clue1Component",
        emits: ["close"],
        components: {
            Carousel,
            CarouselSlide
        },
        setup() {
            const state = reactive({
                team_id: "",
                solveFlg: false,
                slides: []
            })
            const store = useStore(storeKey)
            const team_id = computed(() => store.state.userEvent.team_id)
            state.team_id = team_id

            let images = []
            if(state.team_id == 1) {
                images = ['/assets/item/clue/01.png']
            }
            if(state.team_id == 2) {
                images = ['/assets/item/clue/02.png','/assets/item/clue/03.png']
            }
            if(state.team_id == 3) {
                images = ['/assets/item/clue/04.png']
            }
            if(state.team_id == 4) {
                images = ['/assets/item/clue/05.png']
            }
            const slides = ref(images)

            const toggleModal = (flag) => {
                return state.solveFlg = !flag
            }

            const visibleSlide = ref(0)
            const slidesLength = computed(() => slides.value.length)

            const next = () => {
                if(visibleSlide.value >= slidesLength.value - 1) {
                    visibleSlide.value = 0
                } else {
                    visibleSlide.value++
                }
            }

            const prev = () => {
                if(visibleSlide.value <= 0) {
                    visibleSlide.value = slidesLength.value - 1
                } else {
                    visibleSlide.value--
                }
            }

            return {
                state,
                toggleModal,
                visibleSlide,
                next,
                prev,
                close,
                slides
            }
        }
    })
</script>

<style lang="sass">
    .modal-overlay 
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        display: flex
        justify-content: center
        align-items: center
        z-index: 2
    #clueOneComponent
        position: absolute
        width: 650px
        @media (pointer:none), (pointer:coarse)
            width: 65%
            position: fixed
            top: 0
            left: 15%
            height: 100%
        height: 77%
        z-index: 1
        top: 15%
        left: 55%
        background-image: url(/assets/bg/clue_bg.png)
        background-repeat: no-repeat
        background-position: top
        background-size: contain
        .container
            overflow-y: hidden
            height: 100%
        .clue-wrap
            @media (pointer:none), (pointer:coarse)
                padding: 3% 10%
            padding: 4%
            p
                font-size: 14px
                @media (pointer:none), (pointer:coarse)
                    font-size: 12px
        .close
            text-align: left
            img
                width: 5%
        article
            -webkit-user-select: none
            -moz-user-select: none
            -ms-user-select: none
            user-select: none
        .clue-content
            height: 280px
            overflow-x: hidden
            @media (pointer:none), (pointer:coarse)
                height: 60%
</style>

/**
* Clue2Component
*
*/
<template>
    <div class="modal-overlay" @click.self="$emit('close')">
        <div id="clueTwoComponent">
            <section class="container">
                <div class="row">
                    <article class="clue-wrap col-12" @click.right.prevent @copy.prevent @paste.prevent>
                        <div class="">
                            <img src="/assets/item/clue/clue2_title.png" style="width: 30%" class="mb-2">
                        </div>
                        <div v-if="state.team_id === 1" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・自陣②【井伊直政】から南西に自陣③【福島正則】がいる。</p>
                                    <p className="mb-0">・たぬき平野から西に行けば自陣③【福島正則】が布陣している</p>
                                    <p className="mb-0">・双子茶屋の西に敵陣③【小西行長】がある。</p>
                                    <p className="mb-0">・くノ一の隠れ家よりすぐ北に敵陣⑥【小早川秀秋】が布陣している</p>
                                    <p className="mb-0">・侍修行道場の南東にサルがたくさん集まる場所がある</p>
                                    <p className="mb-0">・落ち武者の谷より北に敵陣⑧【安国寺恵瓊】が布陣している</p>
                                    <p className="mb-0">・敵陣⑤【大谷吉継】と敵陣⑥【小早川秀秋】は隣接している</p>
                                    <p className="mb-0">・敵陣①【島左近】より東にある村から矢が飛んできた。優秀な弓取りが集まる村あるのだろう</p>
                                    <p className="mb-0">・敵陣⑪【長宗我部盛親】のはるか北西に敵本陣【石田三成】は布陣している</p>
                                    <p className="mb-0">・敵陣⑦【平塚為広】の東に西軍の兵糧庫は見つからない</p>
                                    <p className="mb-0">・敵陣⑪【長宗我部盛親】は【密書の謎2】の行に布陣している</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.team_id === 2" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・双子茶屋のすぐ東には三つ子茶屋がある。競合店のようだ。</p>
                                    <p className="mb-0">・悪代官の家のすぐ北に敵陣④【宇喜多秀家】が布陣している</p>
                                    <p className="mb-0">・敵陣⑤【大谷吉継】は悪代官の家の南西に陣取っている</p>
                                    <p className="mb-0">・落ち武者の谷はるか西にくノ一の隠れ家があるらしい</p>
                                    <p className="mb-0">・敵陣④【宇喜多秀家】よりはるか東にサルの祠</p>
                                    <p className="mb-0">・美剣士の沼の北東に敵陣⑧【安国寺恵瓊】あり</p>
                                    <p className="mb-0">・三つ子茶屋の北に弓取りの村が確認された</p>
                                    <p className="mb-0">・黄金寺より南をみるとうっすらと落ち武者の谷を発見した</p>
                                    <p className="mb-0">・敵陣①【島左近】は職人の村で武具を買い占めてはるか西に消えていった</p>
                                    <p className="mb-0">・敵陣④【宇喜多秀家】の北にうっすらと笹尾山が見え隠れしている</p>
                                    <p className="mb-0">・謀反人が集まる洞窟は【密書の謎2】にある</p>
                                    <p className="mb-0">・敵本陣の【密書の謎3】に西軍の兵糧庫を発見した！</p>
                                </div>
                            </div>
                        </div>


                        <div v-if="state.team_id === 3" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・本陣【徳川家康】からはるか南西に傷を癒すという噂の足軽の湯がある</p>
                                    <p className="mb-0">・忍びの里から西に行けば足軽の湯がある</p>
                                    <p className="mb-0">・首狩り平野より北西に敵陣⑦【平塚為広】の陣がある</p>
                                    <p className="mb-0">・敵陣⑦【平塚為広】より北西に悪代官の家がある</p>
                                    <p className="mb-0">・敵陣⑩【毛利秀元】から北をみればうっすらとサルが奉られている場所が見える</p>
                                    <p className="mb-0">・黄金寺より南に敵陣⑧【安国寺恵瓊】が布陣している</p>
                                    <p className="mb-0">・くノ一の隠れ家は敵陣⑤【大谷吉継】の南に存在するらしい</p>
                                    <p className="mb-0">・自陣③【福島正則】から北を見れば敵陣①【島左近】の陣がうっすら確認された</p>
                                    <p className="mb-0">・敵陣⑧【安国寺恵瓊】の南に敵陣⑪【長宗我部盛親】が布陣している</p>
                                    <p className="mb-0">・笹尾山の南に敵本陣【石田三成】が展開しているようだ</p>
                                    <p className="mb-0">・弓取の集落は【密書の謎2】にある</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.team_id === 4" class="clue-content">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mb-0">・無双の丘の北東に敵陣⑨【吉川広家】が布陣している</p>
                                    <p className="mb-0">・自陣⑥【山内一豊】の南に⑨【吉川広家】が布陣している</p>
                                    <p className="mb-0">・無双の丘の南、忍びの里の東には美剣士の沼がある</p>
                                    <p className="mb-0">・黄金寺よりはるか西に敵陣④【宇喜多秀家】が布陣している</p>
                                    <p className="mb-0">・足軽の湯より北を見ればうっすらと敵陣④【宇喜多秀家】が確認された</p>
                                    <p className="mb-0">・うつけの焼け跡のすぐ北東に職人達が集う村を発見した</p>
                                    <p className="mb-0">・職人の村には弓矢を買いに来る弓取りが沢山いる。西のほうにうっすらと弓取りの村がみえる</p>
                                    <p className="mb-0">・浪人の酒場から東をみればうっすらとした落ち武者の谷が確認された</p>
                                    <p className="mb-0">・敵陣⑦【平塚為広】のはるか東に敵陣⑧【安国寺恵瓊】あり</p>
                                    <p className="mb-0">・敵陣⑪【長宗我部盛親】は温泉に入ってはるか東のほうに消えていった</p>
                                    <p className="mb-0">・【密書の謎2】は敵陣①【島左近】の陣と隣接している</p>
                                    <p className="mb-0">・徳川家康の【密書の謎3】に西軍の兵糧庫は見当たらない</p>
                                </div>
                            </div>
                        </div>
                        <div className="my-2">
                            <img @click="toggleModal(state.solveFlg)" src="/assets/item/clue/solve_btn.png" style="width:25%">
                        </div>
                        <div class="close">
                            <img @click="$emit('close')" src="/assets/item/other/close_black.png">
                        </div>
                    </article>
                </div>
            </section>
            <Carousel
                v-show="state.solveFlg"
                @close="toggleModal(state.solveFlg)"
                @next="next"
                @prev="prev"
                :visibleSlide="visibleSlide"
                :slidesLength="slides.length"
            >
                <CarouselSlide
                    v-for="(slide, index) in slides"
                    :key="slide"
                    :index="index"
                    :visibleSlide="visibleSlide"
                    >
                    <img :src="slide"/>
                </CarouselSlide>
            </Carousel>
        </div>
    </div>
</template>

<script>
    import { useStore,storeKey } from 'vuex'
    import { ref, reactive, computed } from 'vue'
    import Carousel from '@c/Carousel.vue'
    import CarouselSlide from '@c/CarouselSlide.vue'

    export default ({
        name: "Clue2Component",
        emits: ['close'],
        components: {
            Carousel,
            CarouselSlide
        },
        setup() {
            const state = reactive({
                team_id: "",
                solveFlg: false,
                slides: []
            })
            const store = useStore(storeKey)
            const team_id = computed(() => store.state.userEvent.team_id)
            state.team_id = team_id

            let images = []
            if(state.team_id == 1) {
                images = ['/assets/item/clue/06.png']
            }
            if(state.team_id == 2) {
                images = ['/assets/item/clue/07.png','/assets/item/clue/09.png']
            }
            if(state.team_id == 3) {
                images = ['/assets/item/clue/08.png']
            }
            if(state.team_id == 4) {
                images = ['/assets/item/clue/10.png','/assets/item/clue/11.png']
            }
            const slides = ref(images)

            const toggleModal = (flag) => {
                return state.solveFlg = !flag
            }
            const visibleSlide = ref(0)
            const slidesLength = computed(() => slides.value.length)

            const next = () => {
                if(visibleSlide.value >= slidesLength.value - 1) {
                    visibleSlide.value = 0
                } else {
                    visibleSlide.value++
                }
            }

            const prev = () => {
                if(visibleSlide.value <= 0) {
                    visibleSlide.value = slidesLength.value - 1
                } else {
                    visibleSlide.value--
                }
            }

            return {
                state,
                toggleModal,
                visibleSlide,
                next,
                prev,
                close,
                slides
            }
        }
    })
</script>

<style lang="sass" scoped>
    .modal-overlay 
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        display: flex
        justify-content: center
        align-items: center
        z-index: 2
    #clueTwoComponent
        position: absolute
        width: 650px
        @media (pointer:none), (pointer:coarse) 
            width: 65%
            position: fixed
            top: 0
            left: 15%
            height: 100%
        height: 77%
        z-index: 1
        top: 15%
        left: 55%
        background-image: url(/assets/bg/clue_bg.png)
        background-repeat: no-repeat
        background-position: top
        background-size: contain
        .container
            overflow-y: hidden
            height: 100%
        .clue-wrap
            @media (pointer:none), (pointer:coarse) 
                padding: 3% 10%
            padding: 4%
            p
                font-size: 14px
                @media (pointer:none), (pointer:coarse)
                    font-size: 12px
        .close
            text-align: left
            img
                width: 5%
        article
            -webkit-user-select: none
            -moz-user-select: none
            -ms-user-select: none
            user-select: none
        .clue-content
            height: 280px
            overflow-x: hidden
            @media (pointer:none), (pointer:coarse)
                height: 50%
</style>

<template>
    <section class="main">
        <router-view />
    </section>
</template>

<script>
import { onMounted, provide } from "vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default ({
    setup() {
        provide('isMobile', /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(navigator.userAgent))
        const store = useStore()
        const router = useRouter()

        onMounted(async () => {
            // Going to ref store object, so "await" is required.
            await store.dispatch('fetchUserEvent')
            const stage = store.state.userEvent.event.stage
            document.title = store.state.userEvent.teamName;
            router.push({
                name: stage
            })
        })
    }
})
</script>

<style>
.main {
    background-size: cover;
    background-image: url(/assets/bg/base.png);
    height: 100vh;
    display: grid;
    align-items: center;
}

@media screen and (min-width: 992px) {
.main::before {
    content: "";
    background-image: url(/assets/bg/edge.png);
    background-repeat: repeat-x;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
    height: 25px;
}
.main::after {
    content: "";
    background-image: url(/assets/bg/edge.png);
    background-repeat: repeat-x;
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
}
}
</style>

/**
* HelpComponent
*
*/

<template>
    <div class="modal-overlay" @click.self="$emit('close')">
        <div class="content">
            <img src="/assets/help/introduction.png">
            <div class="close mt-2">
                <img @click="$emit('close')" src="/assets/item/other/close_white.png">
            </div>
        </div>
    </div>
</template>



<script>
    export default ({
        name: "HelpComponent",
        emits: ["close"],
        setup() {}
    })
</script>

<style lang="scss" scoped>
.modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}
.content {
    display: grid;
    width:  65%;
    height: 75%;
    flex-wrap: wrap;
    img {
        max-width: 100%;
        object-fit: contain;
    }
}
.close{
    text-align: right;
    img {
        width: 5%
    }

}
</style>


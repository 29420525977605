<template>
    <div v-show="visibleSlide === index" class="carousel-slide">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: [
        'visibleSlide',
        'index',
    ]
}
</script>

<style lang="sass">
    .carousel-slide
        img
            object-fit: contain
            max-width: 100%
</style>

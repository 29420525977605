<template>
    <div id="MapTeam" class="map-team">
    <div class="main-grid">
        <div v-if="!isItemSelectShowen" class="map-grid">
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/north.png">
            </div>
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/west.png">
            </div>
            <div class="index-grid">
                <div/>
                <div class="index-x">
                    <div class="index-cell" v-for="i in teamP[teamId].x_index" :key="i">
                        <img :src="'/assets/map/symbol/x/' + i + '.png'" alt="">
                    </div>
                </div>
                <div class="index-y">
                    <div class="index-cell" v-for="i in teamP[teamId].y_index" :key="i">
                        <img :src="'/assets/map/symbol/y/' + i + '.png'" alt="">
                    </div>
                </div>
                <div class="map" :style="{backgroundImage: 'url(/assets/bg/map_team_' + teamId + '.png)'}">
                    <div class="map-cell"
                        :class="{ 'map-cell-black-out': !v.result && stage === 'result' }"
                        v-for="(v, k) in teamField.entries()"
                        :key="v"
                        @click.prevent="toggleItemSelect(true, k)"
                    >
                        <img
                            v-if="v[1].category !== ''"
                            :src="'/assets/item/card/' + v[1].category + '/' + v[1].card_no + '.png'"
                            alt="">
                        </div>
                </div>
            </div>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/east.png">
            </div>
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/south.png">
            </div>
            <div/>
        </div>
        <div v-if="!isItemSelectShowen" class="card-tab-grid">
            <div class="menu-btn">
                <img src="/assets/bg/select_item_btn_mobile.png" @click.prevent="toggleItemSelect(!isItemSelectShowen)">
            </div>
        </div>
        </div>
        <ShowCardMobile
            v-show="cardDetail.isShowen"
            @close="toggleCardDetail('', 0, false)"
        />
    </div>
    <div class="map-tab">
        <img src="/assets/bg/map_tab_team_mobile.png">
    </div>
</template>

<script>
import { useStore, storeKey } from 'vuex'
import { computed, onMounted, reactive, provide } from 'vue'
import ShowCardMobile from '@c/ShowCardMobile'

export default {
    name: "MapGroupMobile",
    components: {
        ShowCardMobile,
    },
    setup() {
        const genCell = (cnt) => {
            return [...Array(cnt).keys()].map(i => ++i)
        };
        const store = useStore(storeKey)
        const stage = computed(() => store.state.userEvent.event.stage)
        const groupField = computed(() => store.state.groupField)
        const teamField = computed(() => store.state.teamField)
        const teamId = computed(() => store.state.userEvent.team_id)
        const teamP = {
            1: { x_index: [1, 2, 3, 4, 5],  y_index: [1, 2, 3, 4, 5]},
            2: { x_index: [6, 7, 8, 9, 10], y_index: [1, 2, 3, 4, 5]},
            3: { x_index: [1, 2, 3, 4, 5],  y_index: [6, 7, 8, 9, 10]},
            4: { x_index: [6, 7, 8, 9, 10],  y_index: [6, 7, 8, 9, 10]},
        }

        const isItemSelectShowen = computed(() => store.state.isItemSelectShowen)
        const toggleItemSelect = (isShow, selectedFieldIndex = 0) => {
            store.dispatch('toggleItemSelect', { isShow, selectedFieldIndex })
        }

        const checkResult = (fieldItem) => {
            if (fieldItem.result) {
                return "result-corrent"
            }
        }
        const cardDetail = reactive({
            category: "",
            card_no: 0,
            isShowen: false,
        })
        provide('cardDetail', cardDetail)
        const toggleCardDetail = (category, card_no, isShowen) => {
            cardDetail.category = category
            cardDetail.card_no = card_no
            cardDetail.isShowen = isShowen
        }

        onMounted(async () => {
            // Going to ref store object, so "await" is required.
            await store.dispatch('fetchGroupField')
            await store.dispatch('fetchTeamFieldItem')
        })

        return {
            genCell,
            stage,
            teamField,
            teamId,
            teamP,
            groupField,
            toggleItemSelect,
            cardDetail,
            isItemSelectShowen,
            toggleCardDetail,
            checkResult,
        }
    }
}
</script>
<style lang="scss" scoped>
.map-team {
    width: 100%;
    height: 100%;
    flex-grow: 1;
}
.map-tab {
    position: absolute;
    top: 20%;
    right: 28%;
    img {
        width: 80%;
    }
}
.main-grid {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template: 100% / 70% 30%;
    pointer-events: auto;
}
.map-grid {
    width: 100%;
    height: 100%;
    padding: 5% 0% 5% 5%;
    display: grid;
    grid-template: 5% 90% 5% / 5% 90% 5%;
}
.card-tab-grid {
    width: 90%;
    display: grid;
    align-items: end;
    padding-bottom: 20%;
}
.menu-btn {
    width: 100%;
    justify-content: start;
    img {
        width: 100%;
        object-fit: contain;
    }
}
.direction-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 60%;
        object-fit: contain;
    }
}
.index-grid {
    display: grid;
    grid-template: 6% 94% / 6% 94%;
}
.index-x {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}
.index-y {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
}
.index-x .index-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9e933e;
    border: 1px black solid;
    img {
        max-width: 20%;
        object-fit: contain;
    }
}
.index-y .index-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9e933e;
    border: 1px black solid;
    img {
        max-width: 70%;
        object-fit: contain;
    }
}
.map {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    grid-template: repeat(5, 1fr) / repeat(5, 1fr);
}
.map-cell {
    border: 1px black solid;
    display: grid;
    align-items: center;
    justify-items: center;
    img {
        max-width: 100%;
        object-fit:contain;
    }
}
.map-cell-black-out {
    backdrop-filter: brightness(70%) grayscale(100%);
    img {
        filter: brightness(70%) grayscale(100%);
    }
}
</style>

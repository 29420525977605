<template>
    <div class="modal-overlay" @click="$emit('close')">
        <div class="content">
            <img v-if="cardDetail.category !== ''" :src="'/assets/item/card/' + cardDetail.category + '/' + cardDetail.card_no + '.png'">
            <img v-else src="/assets/item/card/blank.png">
        </div>
    </div>
</template>

<script>
import { inject } from 'vue'

export default ({
    name: "ShowCardMobile",
    emits: ["close"],
    setup() {
        const cardDetail = inject('cardDetail')
        return {
            cardDetail,
        }
    }
})
</script>
<style lang="scss" scoped>
.modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
}
.content {
    display: grid;
    img {
        object-fit: contain;
    }
}
</style>


import Main from '@root/views/layout/main.layout.vue';
import Lobby from '@root/views/pages/Lobby';
import First from '@root/views/pages/First';
import Second from '@root/views/pages/Second';
import Final from '@root/views/pages/Final';
import Result from '@root/views/pages/Result';
import MapGroup from '@c/MapGroup'
import MapTeam from '@c/MapTeam'

const routes = [
    {
        path: '/',
        component: () => import('@root/views/layout/main.layout.vue'),
        children: [
            {
                path: '/',
                component: Main,
                name: 'main',
            },
            {
                path: '/lobby',
                component: Lobby,
                name: 'lobby'
            },
            {
                path: '/first',
                redirect: '/team',
                component: First,
                name: 'first',
                children: [
                    {
                        path: '/group',
                        name: 'MapGroup',
                        component: MapGroup,
                    },
                    {
                        path: '/team',
                        name: 'MapTeam',
                        component: MapTeam,
                        beforeEnter(to, from, next) {
                            if (typeof(from.name) === 'undefined') {
                                next('/')
                            } else {
                                next()
                            }
                        }
                    },
                ],
            },
            {
                path: '/second',
                redirect: '/group',
                component: Second,
                name: 'second',
                children: [
                    {
                        path: '/group',
                        name: 'MapGroup',
                        component: MapGroup,
                    },
                    {
                        path: '/team',
                        name: 'MapTeam',
                        component: MapTeam,
                        beforeEnter(to, from, next) {
                            if (typeof(from.name) === 'undefined') {
                                next('/')
                            } else {
                                next()
                            }
                        }
                    },
                ],
            },
            {
                path: '/final',
                redirect: '/group',
                component: Final,
                name: 'final',
                children: [
                    {
                        path: '/group',
                        name: 'MapGroup',
                        component: MapGroup,
                        beforeEnter(to, from, next) {
                            if (typeof(from.name) === 'undefined') {
                                next('/')
                            } else {
                                next()
                            }
                        }
                    },
                ],
            },
            {
                path: '/result',
                redirect: '/group',
                component: Result,
                name: 'result',
                children: [
                    {
                        path: '/group',
                        name: 'MapGroup',
                        component: MapGroup,
                        beforeEnter(to, from, next) {
                            if (typeof(from.name) === 'undefined') {
                                next('/')
                            } else {
                                next()
                            }
                        }
                    },
                ],
            },
        ]
    },
];

export default routes;

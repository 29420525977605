<template>
    <div id="MapTeam" class="map-team">
    <div class="main-grid">
        <div class="map-grid">
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/north.png">
            </div>
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/west.png">
            </div>
            <div class="index-grid">
                <div></div>
                <div class="index-x">
                    <div class="index-cell" v-for="i in teamP[teamId].x_index" :key="i">
                        <img :src="'/assets/map/symbol/x/' + i + '.png'" alt="">
                    </div>
                </div>
                <div class="index-y">
                    <div class="index-cell" v-for="i in teamP[teamId].y_index" :key="i">
                        <img :src="'/assets/map/symbol/y/' + i + '.png'" alt="">
                    </div>
                </div>
                <div class="map" :style="{backgroundImage: 'url(/assets/bg/map_team_' + teamId + '.png)'}">
                    <div class="map-cell"
                         v-for="v in teamField.entries()"
                         :key="v"
                         @drop="onDrop($event, v[1], v[0])"
                         @dragenter.prevent
                         @dragover.prevent
                    >
                        <img v-if="v[1].category !== ''"
                             :src="'/assets/item/card/' + v[1].category + '/' + v[1].card_no + '.png'"
                             class="map-cell-card"
                             :draggable=" (v[1].category !== '' && v[1].category !== 'rock') ? 'true' : 'false' "
                             @dragstart="startDrag($event, v[1], v[0])"
                        >
                    </div>
                </div>
            </div>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/east.png">
            </div>
            <div/>
            <div class="direction-symbol">
                <img src="/assets/map/symbol/south.png">
            </div>
            <div/>

            <div v-if="!plotResult.success" class="messages container-fluid flash-container">
                <div class="alert alert-danger flash-message" role="alert" >
                    {{ plotResult.message }}
                </div>
            </div>
        </div>
        <div class="item-grid">
            <Clue1Component
                v-show="state.clue1Flg"
                @close="toggleModal('clue1',state.clue1Flg)"
                @solve="toggleModal('solve1',state.solve1Flg)"
            />
            <Clue2Component
                v-show="state.clue2Flg"
                @close="toggleModal('clue2',state.clue2Flg)"
                @solve="toggleModal('solve2',state.solve2Flg)"
            />

            <div class="card-grid">
                <div class="card-tab-grid">
                    <div class="card-tab" v-for="c in ['home', 'enemy', 'nature', 'structure']" :key="c">
                        <img @click="itemCategory = c" :src="'/assets/item/card/tab/' + c + '.png'">
                    </div>
                </div>
                <div class=card-content-grid>
                    <div class="card-content-showcase"
                         @drop="onDrop($event, {category: itemCategory})"
                         @dragenter.prevent
                         @dragover.prevent

                    >
                        <div class="card-content" v-for="v in teamItem[itemCategory]" :key="v">
                            <img :src="'/assets/item/card/' + itemCategory + '/' + v + '.png'"
                                 alt=""
                                 :draggable="true"
                                 @dragstart="startDrag($event, {category: itemCategory, card_no: v}, null)"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import { inject, ref, onMounted, computed } from 'vue'
import { useStore, storeKey } from 'vuex'
import HelpComponent from '@c/HelpComponent.vue';
import Clue1Component from '@c/Clue1Component.vue';
import Clue2Component from '@c/Clue2Component.vue';

    export default {
        name: "MapTeam",
        components: {
            HelpComponent,
            Clue1Component,
            Clue2Component,
        },
        setup() {
            const store = useStore(storeKey)
            const teamField = computed(() => store.state.teamField)
            const teamItem = computed(() => store.state.teamItem)
            const teamId = computed(() => store.state.userEvent.team_id)
            const stage = computed(() => store.state.userEvent.event.stage)
            const teamP = {
                1: { x_index: [1, 2, 3, 4, 5],  y_index: [1, 2, 3, 4, 5]},
                2: { x_index: [6, 7, 8, 9, 10], y_index: [1, 2, 3, 4, 5]},
                3: { x_index: [1, 2, 3, 4, 5],  y_index: [6, 7, 8, 9, 10]},
                4: { x_index: [6, 7, 8, 9, 10],  y_index: [6, 7, 8, 9, 10]},
            }

            const itemCategory = ref('home')

            const startDrag = (event, src, srcIndex=null) => {
                event.dataTransfer.dropEffect = 'move'
                event.dataTransfer.effectAllowed = 'move'
                event.dataTransfer.setData('category', src.category)
                event.dataTransfer.setData('card_no', src.card_no)
                event.dataTransfer.setData('index', srcIndex)
            }

            const onDrop = async (event, dst, dstIndex=null) => {
                let srcIndex = parseInt(event.dataTransfer.getData('index'))
                if (srcIndex != srcIndex) {
                    srcIndex = null
                }
                if ((dstIndex === null && srcIndex === null)
                    || (dstIndex !== null && dst.category !== '')) {
                    return false
                }
                const src = {
                    category: event.dataTransfer.getData('category'),
                    card_no: parseInt(event.dataTransfer.getData('card_no')),
                }
                store.dispatch('updateFieldItem', {src, srcIndex, dstIndex})
            }

            const state = inject('state')
            const toggleModal = (type, flag) => {
                if (type === "clue1"){
                    if(state.clue2Flg) state.clue2Flg = false;
                    state.clue1Flg = !flag
                }
                if (type === "clue2") {
                    if(state.clue1Flg) state.clue1Flg = false;
                    state.clue2Flg = !flag
                }
            }
            const plotResult = computed(() => store.state.plotResult)

            onMounted(async () => {
                await store.dispatch('fetchTeamFieldItem')
            })

            return {
                teamField,
                teamItem,
                teamId,
                teamP,
                startDrag,
                onDrop,
                itemCategory,
                state,
                toggleModal,
                stage,
                plotResult,
            }
        }
    }
</script>
<style lang="scss" scoped>
.map-team {
    flex-grow: 1;
    padding: 35px;
    background-image: url(/assets/bg/wrap.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    z-index: 2;
    position: relative;
    pointer-events: none;
}
.main-grid {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template: 100% / 60% 40%;
    pointer-events: auto;
}

/* map styles */
.map-grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: 5% auto 5% / 4% auto 4%;
}
.direction-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 60%;
        object-fit: contain;
    }
}
.index-grid {
    display: grid;
    grid-template-columns: 5% 1fr;
    grid-template-rows: 5% 1fr;
    height:515px
}
.index-x {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}
.index-y {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
}
:is(.index-x, .index-y) .index-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9e933e;
    border: 1px black solid;
    img {
        object-fit: contain;
        max-width: 60%;
        max-height: 60%;
    }
}
.map {
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template: repeat(5, 1fr) / repeat(5, 1fr);
}
.map-cell {
    border: 1px black solid;
    display: grid;
    align-items: center;
    justify-items: center;
    img {
        max-width: 80%;
        object-fit:contain;
    }
}
.map-cell-card {
    max-width: 100%!important
}

/* item styles */
.item-grid {
    display: grid;
    margin-left: 5%;
    margin-bottom: 7%;
}
.clue-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        img {
            max-width: 70%;
            object-fit: contain;
        }
    }
}
.card-grid {
    display: grid;
    grid-template-rows: 10% 90%;
}
.card-tab-grid {
    display: grid;
    margin: 0 4%;
    grid-template-columns: repeat(4, 25%);
    align-items: end;

    .card-tab {
        img {
            max-width: 100%;
            object-fit: contain;
            opacity: 0.7;
        }
    }
}
.card-content-grid {
    display: grid;
    grid-template-rows: 5% 90% 5%;
    background-color: black;
}
.card-content-grid::before {
    content: "";
    background-image: url(/assets/item/card/edge.png);
    background-repeat: repeat-x;
}
.card-content-grid::after {
    content: "";
    background-image: url(/assets/item/card/edge.png);
    background-repeat: repeat-x;
}
.card-content-showcase {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    grid-auto-rows: 30%;
    overflow-y: scroll;
}
.card-content {
    display: grid;
    align-items: center;
    justify-items: center;
    img {
        max-width: 85%;
        object-fit:contain;
    }
}
.flash-container {
    position: absolute;
    width: auto;
    left: 15%;
    bottom: 20%;
}

.flash-message {
    font-weight: bold;
    position: relative;
    left: 0;
    right: 0;
    margin-top: 10px;
    z-index: 100;
    opacity: 0.9;
}
</style>

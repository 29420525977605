<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "secondPage",
}
</script>
<style>
</style>

<template>
    <div class="item-select">
        <div class="main-grid">
            <div class="selector-grid">
                <div class="area-title">
                    <img src="/assets/bg/select_position.png">
                </div>
                <div class="area-title">
                    <img src="/assets/bg/select_card.png">
                </div>

                <div class="position-select-grid">
                    <select v-model="selectedPos.y" @change="onChangeDstIndex($event)">
                        <option v-for="(v, k) in teamP[teamId].y_index" :key="k" :value="k"> {{ v }}</option>
                    </select>
                    <img src="/assets/bg/hyphen.png">
                    <select v-model="selectedPos.x" @change="onChangeDstIndex($event)">
                        <option v-for="(v, k) in teamP[teamId].x_index" :key="k" :value="k"> {{ v }}</option>
                    </select>
                </div>
                <div class="card-preview">
                    <img v-if="preview.category !== ''" :src="'/assets/item/card/' + preview.category + '/' + preview.card_no + '.png'"
                         @click.prevent="toggleItemShowCase(true)"
                         alt=""
                    >
                    <img v-else
                         src="/assets/item/card/blank.png"
                         @click.prevent="toggleItemShowCase(true)"
                         alt=""
                    >
                </div>
            </div>
            <div class="confirm-btn-grid">
                <div class="reset-btn">
                    <img @click.prevent="onField2Item" src="/assets/bg/item_reset_mobile.png">
                </div>
                <div class="confirm-btn">
                    <img @click.prevent="onItem2Field" src="/assets/bg/item_put_mobile.png">
                </div>
                <div class="close-btn">
                    <img @click.prevent="closeItemSelect" src="/assets/item/other/close_black.png">
                </div>
            </div>
        </div>
    </div>
    <ItemShowCaseMobile
    v-show="isItemShowCaseShowen"
    @close="toggleItemShowCase(false)"
    @select="setSrcItem"
    />
</template>
<script>
import { computed, reactive, ref, onMounted } from "vue"
import { useStore } from 'vuex'
import ItemShowCaseMobile from '@c/ItemShowCaseMobile'
export default ({
    name: "ItemSelectMobile",
    components: {
        ItemShowCaseMobile,
    },
    setup() {
        const store = useStore()
        const teamItem = computed(() => store.state.teamItem)
        const teamField = computed(() => store.state.teamField)
        const teamId = computed(() => store.state.userEvent.team_id)
        const fieldIndex = computed(() => store.state.selectedFieldIndex)

        const teamP = {
            1: { x_index: { 0: "一", 1: "二", 2: "三", 3: "四", 4: "五" }, y_index: { 0: "い", 1: "ろ", 2: "は", 3: "に", 4: "ほ" } },
            2: { x_index: { 0: "六", 1: "七", 2: "八", 3: "九", 4: "十" }, y_index: { 0: "い", 1: "ろ", 2: "は", 3: "に", 4: "ほ" } },
            3: { x_index: { 0: "一", 1: "二", 2: "三", 3: "四", 4: "五" }, y_index: { 0: "へ", 1: "と", 2: "ち", 3: "り", 4: "ぬ" } },
            4: { x_index: { 0: "六", 1: "七", 2: "八", 3: "九", 4: "十" }, y_index: { 0: "へ", 1: "と", 2: "ち", 3: "り", 4: "ぬ" } },
        }

        const selectedPos = reactive({ x: fieldIndex.value % 5, y: fieldIndex.value / 5 | 0 })

        const onChangeDstIndex = () => {
            store.dispatch('updateSelectedFieldIndex', parseInt(selectedPos.x) + parseInt(selectedPos.y * 5))
            const tmpSrc = store.getters.getFieldCard(fieldIndex.value)
            _changeSrc(tmpSrc.category, tmpSrc.card_no, 'field')
        }

        let preview = reactive({
            category: '',
            card_no: 0,
            refFrom: 'field',
        })
        const isItemShowCaseShowen = ref(false)
        const toggleItemShowCase = (v) => {
            if (preview.category === 'rock') {
                alert('岩にはカードが設置出来ません。')
                return
            }
            isItemShowCaseShowen.value = v
        }

        const setSrcItem = async (category, card_no) => {
            _changeSrc(category, card_no, 'item')
            toggleItemShowCase(false)
        }

        const onField2Item = async () => {
            if (preview.refFrom === 'item') {
                alert('マップに設置されていないカードはやり直し出来ません。')
                return
            } else if (preview.category === 'rock') {
                alert('岩はやり直し出来ません。')
                return
            } else if (preview.category === '') {
                return
            }

            store.dispatch('updateFieldItem', {
                src: preview,
                srcIndex: fieldIndex.value,
                dstIndex: null,
            })
            store.dispatch('toggleItemSelect', false)
        }

        const onItem2Field = async () => {
            if (preview.refFrom === 'field') {
                return
            }

            store.dispatch('updateFieldItem', {
                src: preview,
                srcIndex: null,
                dstIndex: fieldIndex.value,
            })
            store.dispatch('toggleItemSelect', false)
        }

        const closeItemSelect = () => {
            store.dispatch('toggleItemSelect', false)
        }

        const _changeSrc = (category, card_no, refFrom) => {
            if (preview.category === category && preview.card_no === card_no) return
            preview.category = category
            preview.card_no = card_no
            if (refFrom !== null)  preview.refFrom = refFrom
        }

        onMounted(() => {
            const tmpSrc = store.getters.getFieldCard(fieldIndex.value)
            _changeSrc(tmpSrc.category, tmpSrc.card_no, null)
        })

        return {
            teamItem,
            teamField,
            teamId,
            fieldIndex,
            onChangeDstIndex,
            preview,
            isItemShowCaseShowen,
            toggleItemShowCase,
            setSrcItem,
            teamP,
            selectedPos,
            closeItemSelect,
            onField2Item,
            onItem2Field,
        }
    },
})
</script>
<style  lang="scss" scoped>
.item-select {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    z-index: 2;
}
.main-grid {
    background-image: url(/assets/bg/item_select_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 100%;
    padding: 10% 5%;
    display: grid;
    grid-template-rows: 80% 20%;
}
.selector-grid {
    display:grid;
    grid-template: 20% 80% / 50% 50%;
    padding: 5%;
}
.confirm-btn-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.reset-btn {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100%;
        object-fit:contain;
    }
}
.confirm-btn {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100%;
        object-fit:contain;
    }
}
.close-btn {
    width: 100%;
    display: grid;
    justify-content: flex-end;
    align-items: center;
    img {
        object-fit:contain;
    }
}
.area-title {
    text-align: center;
}
.position-select-grid {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    justify-content: space-evenly;
    align-items: center;
}
.card-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 45%;
        object-fit: contain;
    }
}
.item-select-modal {
}
</style>

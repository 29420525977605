<template>
    <div id="lobby-bg">
        <div class="lobby">
            <div class="notification">
                イベント開始までしばらくお待ちください
            </div>
            <div class="menu">
                <div class="menu-btn hover">
                    <Refresh/>
                </div>
                <div class="menu-btn hover">
                    <img src="/assets/bg/lobby_sekigahara.png" @click.prevent="toggleComponent('dict', true)">
                </div>
            </div>
        </div>
    </div>
    <div class="logout hover">
        <Logout stage="lobby"/>
    </div>
    <DictComponent
        v-show="showDict"
        @close="toggleComponent('dict', false)"
    />
</template>

<script>
import { ref } from "vue"

import DictComponent from '@c/DictComponent.vue';
import Logout from '@c/Logout';
import Refresh from '@c/Refresh';

export default {
    components: {
        DictComponent,
        Logout,
        Refresh,
    },
    setup() {
        const showDict = ref(false)
        const toggleComponent = (target, isShowen) => {
            if (target === 'dict') {
                showDict.value = isShowen
            }
        }

        return {
            showDict,
            toggleComponent,
        }
    }

}
</script>
<style lang="scss" scoped>
#lobby-bg {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #000000e0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.lobby {
    text-align: center;
    width: 100%;
    height: 50vh;
}
.notification {
    display: grid;
    align-items: center;
    height: 50%;
    font-size: 2.5em;
    font-weight: bold;
    color: #fff;
}
.menu {
    height: 50%;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}
.menu-btn {
    img {
        -webkit-user-drag: none;
        width: 65%;
        object-fit: contain;
    }
}
.menu-btn:active{
    padding-top:6px;
    padding-bottom:2px;
}
.logout {
    position: fixed;
    right: 5px;
    bottom: 30px;
    width: 45px;
    z-index: 3;
}
.hover:hover {
    filter: brightness(90%);
    cursor: pointer;
}
</style>

/** 
* SimpleMessageComponent
* props [message]
*
*/
<template>
    <div id="background">
        <div class="message">
            {{message}}
        </div>
    </div>
</template>

<script>
    export default ({
        name: "SimpleMessageComponent",
        props: ["message"],
    })
</script>

<style>
    #background {
        display: block;
        width: 100%;
        height: 100vh;
        background: #000000b8;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .message {
        position: absolute;
        top: 45vh;
        margin: 0 auto;
        text-align: center;
        left: 0;
        right: 0;
        font-size: 2.5em;
        font-weight: bold;
        color: #fff;
    }
</style>

require('./bootstrap');
import { createApp } from 'vue';
import App from "./views/App.vue"
import router from "./router/index.js";
import store from "./vuex/index.js";

const app = createApp(App);
app.use(router)
app.use(store)
app.mount('#app');

<template>
    <div v-if="!isMobile" class="wrap">
        <div v-if="stage !== 'lobby'" class="tab-section">
            <div class="d-flex justify-content-start">
                <div class="tab-group" :class="{'activeTab': route.path ==='/group'}">
                    <router-link :to="{name: 'MapGroup'}">
                        <img src="/assets/bg/tab-all.png" class="img-fluid">
                        <p>
                                {{groupName}}全体地図
                        </p>
                    </router-link>
                </div>
                <div class="tab-group" v-if=" ['first', 'second'].includes(stage) " :class="{'activeTab': route.path ==='/team'}">
                    <router-link :to="{name: 'MapTeam'}">
                        <img src="/assets/bg/tab-group.png" class="img-fluid">
                        <p>
                                {{teamName}}
                        </p>
                    </router-link>
                </div>
            </div>
            <div class="d-flex justify-content-start">
                <img v-if="['first', 'second'].includes(stage) && route.path ==='/team'" src="/assets/item/clue/1.png" @click="toggleModal('clue1',state.clue1Flg)">
                <img v-if="stage === 'second' && route.path ==='/team'" src="/assets/item/clue/2.png" @click="toggleModal('clue2',state.clue2Flg)">
            </div>
            <div class="d-flex justify-content-end">
                <img v-if="['first', 'second'].includes(stage)" src="/assets/bg/sekigahara.png" class="img-fluid help" @click="toggleComponent('dict', true)">
                <img v-if="['first', 'second'].includes(stage)" src="/assets/bg/help.png" class="img-fluid help" @click="toggleComponent('help', true)">
                <Refresh/>
                <div v-if="stage === 'final'" class="point-board"><h4>   点</h4></div>
                <div v-if="stage === 'result'" class="point-board"><h4>{{ groupPoint }} 点</h4></div>
            </div>
        </div>
        <router-view></router-view>
        <HelpComponent
            v-show="showHelp"
            @close="toggleComponent('help', false)"
        />
        <div class="logout">
            <Logout :stage="stage"/>
        </div>
    </div>
    <div v-if="isMobile" class="text-center hide-on-landscape">
        <h1>端末を横にしてください</h1>
        <img src="/assets/help/phone_rotate.png">
    </div>
    <div v-if="isMobile" class="hide-on-portrait">
        <div v-if="stage === 'lobby'" id="lobby-bg">
            <div class="lobby">
                <div class="notification">
                    イベント開始までしばらくお待ちください
                </div>
                <div class="menu">
                    <Refresh/>
                    <img class="menu-btn hover" src="/assets/bg/lobby_sekigahara.png" @click.prevent="toggleComponent('dict', true)">
                </div>
                <div class="logout">
                    <Logout :stage="stage"/>
                </div>
            </div>
        </div>
        <div v-else class="mobile">
            <div class="wrap-mobile">
                <ItemSelectMobile v-if="isItemSelectShowen"></ItemSelectMobile>
                <MapTeamMobile v-if="route.path ==='/team'"></MapTeamMobile>
                <MapGroupMobile v-if="route.path ==='/group'"></MapGroupMobile>
            </div>
            <div v-if="['first', 'second'].includes(stage)" class="item-area-mobile">
                <div class="help-mobile">
                    <img src="/assets/bg/sekigahara_mobile.png" class="img-img-fluid" @click="toggleComponent('dict', true)">
                    <img src="/assets/bg/help_mobile.png" class="img-fluid" @click="toggleComponent('help', true)">
                </div>
                <ItemMobile></ItemMobile>
            </div>
            <HelpComponent
                v-show="showHelp"
                @close="toggleComponent('help', false)"
                v-if="['first', 'second'].includes(stage)"
            />
            <div v-if="stage === 'final'" class="point-area-mobile">
                <div/>
                <div>
                    <h1>  P</h1>
                </div>
                <div class="user-action">
                    <Logout :stage="stage"/>
                    <Refresh/>
                </div>
            </div>
            <div v-if="stage === 'result'" class="point-area-mobile">
                <div/>
                <div>
                    <h1>{{ groupPoint }} P</h1>
                </div>
                <div class="user-action">
                    <Logout :stage="stage"/>
                    <Refresh/>
                </div>
            </div>
        </div>
    </div>
    <DictComponent
        v-show="showDict"
        @close="toggleComponent('dict', false)"
    />
</template>

<script>
import { computed, inject, onMounted, provide, reactive, ref } from "vue"
import { useStore,storeKey } from 'vuex'
import { useRoute } from 'vue-router'
import HelpComponent from '@c/HelpComponent.vue';
import DictComponent from '@c/DictComponent.vue';
import MapGroupMobile from '@c/MapGroupMobile';
import MapTeamMobile from '@c/MapTeamMobile';
import ItemMobile from '@c/ItemMobile';
import ItemSelectMobile from '@c/ItemSelectMobile';
import SimpleMessageComponent from '@c/SimpleMessageComponent';
import Logout from '@c/Logout';
import Refresh from '@c/Refresh';

export default ({
    name: "main-layout",
    components: {
        HelpComponent,
        DictComponent,
        MapGroupMobile,
        MapTeamMobile,
        ItemMobile,
        ItemSelectMobile,
        SimpleMessageComponent,
        Logout,
        Refresh,
    },
    setup() {
        const isMobile = inject('isMobile')
        const store = useStore(storeKey)
        const route = useRoute()
        const user = computed(() => store.state.userEvent)
        const stage = computed(() => store.state.userEvent.event.stage)
        const groupName = computed(() => store.state.userEvent.groupName)
        const teamName = computed(() => store.state.userEvent.teamName)
        const groupPoint = computed(() => store.state.groupPoint)

        const state = reactive({
            clue1Flg: false,
            clue2Flg: false,
        })
        provide('state', state)
        const toggleModal = (type, flag) => {
            if (type === "clue1"){
                if(state.clue2Flg) state.clue2Flg = false;
                state.clue1Flg = !flag
            }
            if (type === "clue2") {
                if(state.clue1Flg) state.clue1Flg = false;
                state.clue2Flg = !flag
            }
        }

        const showHelp = ref(false)
        const showDict = ref(false)
        const toggleComponent = (target, isShow) => {
            if (target === 'help') {
                showHelp.value = isShow
            } else if (target === 'dict') {
                showDict.value = isShow
            }
        }
        const isItemSelectShowen = computed(() => store.state.isItemSelectShowen)

        onMounted(async () => {
            await store.dispatch('fetchGroupPoint')
        })

        return {
            isMobile,
            user,
            stage,
            groupName,
            teamName,
            groupPoint,
            showHelp,
            showDict,
            isItemSelectShowen,
            toggleComponent,
            state,
            toggleModal,
            route,
        }
    }
})
</script>

<style lang="scss" scoped>
    .wrap {
        width: 1120px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .tab-section {
        display: grid;
        grid-template-columns: 40% 30% 30%;
        position: relative;
        top: 38px;
        z-index: 1;
        padding: 0 35px;
    }
    .tab-group {
        display: inline-block;
        position: relative;
        max-width: 40%;
        background-repeat: no-repeat;
        background-size: contain;
        left: 3%;
        color: #fff;
        font-weight: bold;
        text-align: center;
    }
    .tab-group:active{
        padding-top:4px;
        padding-bottom:2px;
    }
    .activeTab {
        transform: scale(1.1, 1.2);
    }
    .tab-all p, .tab-group p {
        position: absolute;
        display: inline-block;
        top: 25%;
        left: 0;
        right: 0;
        margin-bottom: 0;
    }
    .tab-group a {
        color: #ffffff;
        text-decoration: none;
    }
    .help {
        width: 25%;
        display: inline-block;
    }
    .reload:active{
        transform: rotate(180deg);
    }
    .point-board {
        background-image: url(/assets/bg/help_bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: grid;
        width: 33%;
        align-items: top;
        justify-content: center;
        h4 {
            margin-top: 20%;
        }
    }
    .mobile {
        height: 100%;
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: 100%;
        align-items: center;
    }
    .wrap-mobile {
        background-image: url(/assets/bg/wrap.png);
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: grid;
        aspect-ratio: 3 / 2;
        width: 100%;
        grid-template: 1fr / 1fr;
        align-items: center;
        justify-content: center;
    }
    .item-area-mobile {
        display: grid;
        align-items: center;
        height: 100%;
    }
    .point-area-mobile {
        display: grid;
        height: 80%;
        grid-template: 30% 40% 30% / 1fr;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
    .user-action {
        justify-content: initial;
        display: flex;
        flex-direction: row-reverse;
    }
    .help-mobile {
        position: absolute;
        right: 0;
        top: 0;
        img {
            max-width: 80%;
            object-fit: contain;
        }
    }
    #lobby-bg {
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: #000000e0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }
    @media (orientation: portrait) {
        .hide-on-portrait {
            display: none !important;
        }
    }
    @media (orientation: landscape) {
        .hide-on-landscape {
            display: none !important;
        }
    }

    .lobby {
        text-align: center;
        width: 100%;
        height: 50vh;
    }
    .notification {
        display: grid;
        align-items: center;
        height: 50%;
        font-size: 1.5em;
        font-weight: bold;
        color: #fff;
    }
    .menu {
        height: 50%;
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
        grid-template-columns: 1fr 1fr;
    }
    .menu-btn {
        -webkit-user-drag: none;
        width: 50%;
        object-fit: contain;
    }
    .menu-btn:active{
        padding-top:6px;
        padding-bottom:2px;
    }
    .max-z-index {
        z-index: 999;
    }
    .logout {
        position: fixed;
        right: 5px;
        bottom: 30px;
        width: 45px;
    }
</style>

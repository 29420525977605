import axios from 'axios'
import { createStore } from 'vuex'

const state = {
    userEvent: {
        event: {}
    },
    groupField: [[], [], [], []],
    groupPoint: 0,
    teamField: [],
    teamItem: {
        home: [],
        enemy: [],
        nature: [],
        structure: [],
    },
    isItemSelectShowen: false,
    selectedFieldIndex: 0,
    groupAnswer: [],
    plotResult: {
        success: true,
        message: "",
    }
}

const mutations = {
    setUserEvent(state, payload) {
        state.userEvent = payload;
    },
    setGroupField(state, payload) {
        state.groupField = payload;
    },
    setGroupPoint(state, payload) {
        state.groupPoint = payload;
    },
    setTeamField(state, payload) {
        state.teamField = payload;
    },
    setTeamItem(state, payload) {
        state.teamItem = payload;
    },
    setItemSelectShowen(state, payload) {
        state.isItemSelectShowen = payload;
    },
    setSelectedFieldIndex(state, payload) {
        state.selectedFieldIndex = payload;
    },
    setGroupAnswer(state, payload) {
        state.groupAnswer = payload;
    },
    setPlotResult(state, payload) {
        state.plotResult = payload;
        if (payload.success == false) {
            setTimeout(() => {
                state.plotResult = {
                    success: true,
                    message: "",
                }
            }, 3000)
        }
    }
}

const actions = {
    async fetchUserEvent({ commit }) {
        const userEvent = await axios.get("userEvent").then(
            (res) => res.data
        );
        commit('setUserEvent', userEvent);
    },
    async fetchGroupField({ commit }) {
        if (!state.userEvent.hasOwnProperty('id')) {
            await this.dispatch('fetchUserEvent')
        }

        const fields = await axios.get("group/field", {
            params: {
                event_id: state.userEvent.event_id,
                group_id: state.userEvent.group_id
            }
        }).then(
            (res) => res.data
        );
        let groupField = fields.map((field) => {
            return JSON.parse(field.content).square
        })
        if (state.userEvent.event.stage !== 'result') {
            commit('setGroupField', groupField);
            return
        }

        // add "result" property to each group field item.
        const raw_answer = await axios.get("group/answer").then(
            (res) => res.data
        ).catch((e) => {
            console.log(e)
        });
        const answer = raw_answer.map(item => item['square'])

        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < answer[i].length; j++) {
                 groupField[i][j].result =
                    groupField[i][j].category === answer[i][j].category &&
                    groupField[i][j].card_no === answer[i][j].card_no
            }
        }
        commit('setGroupField', groupField);
    },

    async fetchGroupPoint({ commit }) {
        if (!state.userEvent.hasOwnProperty('id')) {
            await this.dispatch('fetchUserEvent')
        }

        if (state.userEvent.event.stage !== 'result') return

        const point = await axios.get("group/point", {
            params: {
                event_id: state.userEvent.event_id,
                group_id: state.userEvent.group_id,
            }
        }).then(
            (res) => res.data.point
        ).catch((e) => {
            console.log(e)
        });
        commit('setGroupPoint', point);
    },

    async fetchTeamFieldItem({ commit }) {
        if (!state.userEvent.hasOwnProperty('id')) {
            await this.dispatch('fetchUserEvent')
        }
        const field = await axios.get("team/field", {
            params: {
                event_id: state.userEvent.event_id,
                group_id: state.userEvent.group_id,
                team_id: state.userEvent.team_id,
            }
        }).then(
            (res) => res.data
        ).catch((e) => {
            console.log(e)
        });
        commit('setTeamField', JSON.parse(field.content).square);
        commit('setTeamItem', JSON.parse(field.item.content));
    },

    async updateFieldItem({commit}, {src, srcIndex, dstIndex}) {
        const fieldItem = await axios.post("team/field/update", {
            event_id: state.userEvent.event_id,
            group_id: state.userEvent.group_id,
            team_id: state.userEvent.team_id,
            category: src.category,
            card_no: src.card_no,
            srcIndex: srcIndex,
            dstIndex: dstIndex,
        }).then(
            (res) => res.data
        ).catch((e) => {
            console.log(e)
            alert('データの更新に失敗しました。\n画面を再度読み込んでください。')
        });
        commit('setTeamField', JSON.parse(fieldItem.content).square);
        commit('setTeamItem', JSON.parse(fieldItem.item.content));
        commit('setPlotResult', JSON.parse(fieldItem.result));
    },

    toggleItemSelect({ commit }, { isShow, selectedFieldIndex = 0 }) {
        commit('setItemSelectShowen', isShow)
        commit('setSelectedFieldIndex', selectedFieldIndex)
    },

    async updateSelectedFieldIndex({ commit }, selectedFieldIndex = 0) {
        commit('setSelectedFieldIndex', selectedFieldIndex)
    },

    async getAnswer({ commit }) {
        const answer = await axios.get("group/answer").then(
            (res) => res.data
        ).catch((e) => {
            console.log(e)
        });
        commit('setGroupAnswer', answer.map(item => item['square']));
    },
}

const getters = {
    getFieldCard: (state) => (index = 0) => {
        return state.teamField[index]
    },
}

const store = createStore({
    //namespaced: true,
    state,
    mutations,
    actions,
    getters
});

export default store;
